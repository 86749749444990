import { sharedTypes } from './Constants';
import { all, call, put, takeLatest, select } from 'redux-saga/effects';
import { processRequest } from '../services/Api';
import * as errorHandlerActions from '../components/errorHandler/Actions';
import { IS_ADMIN, IS_MOBILE_APP, MARKETING_URL } from '../services/Constants';
import { identifyHotjar } from '../services/Helper';
import * as sharedActions from './Actions';
import * as dailyReportActions from '../components/dailyReport/Actions';
import { historyReplace } from '../services/History';
import { removeAccessToken } from '../services/AccessToken';
import { getUserId } from '../components/onboarding/Selectors';
import * as learningPathActions from '../pages/learningPath/Actions';
import * as packagesActions from '../pages/packages/Actions';
import * as pushNotificationActions from '../components/appPushNotification/Actions';
import * as signUpActions from '../pages/signUp/Actions';

export default function* () {
	yield all([
		yield takeLatest(sharedTypes.GET_USER_DATA_REQUEST, handleGetUserDataRequest),
		yield takeLatest(sharedTypes.LOGOUT, handleUserLogout),
		yield takeLatest(sharedTypes.UPDATE_USER_DATA_REQUEST, handleUpdateUserDataRequest),
		yield takeLatest(sharedTypes.SEND_EVENT_REQUEST, handleSendEventRequest),
	]);
}

export function* handleGetUserDataRequest({ payload }) {
	try {
		const { options } = payload || {};

		const { data: userData } = yield call(processRequest, 'get_user_data');

		const {
			has_paid_subscription,
			has_password,
			needs_to_fill_grade,
			onboarding_completed,
			child_name,
			grade_id,
			reward,
			sidk_user,
			sidk_closed,
		} = userData || {};

		if (sidk_user && sidk_closed) {
			yield put(sharedActions.logout());
			window.location.href = `${MARKETING_URL}school-gesloten`;
			return;
		}

		yield put(sharedActions.getUserDataSuccess(userData));

		if (IS_ADMIN) return;

		identifyHotjar(userData);

		if (reward?.background_color) {
			yield put(
				sharedActions.setBackgroundColor(reward?.visible ? reward?.background_color : null),
			);
		}

		if (reward?.background_pattern) {
			yield put(
				sharedActions.setBackgroundPattern(
					reward?.visible ? reward?.background_pattern : null,
				),
			);
		}

		if (!has_paid_subscription) {
			yield call(historyReplace, '/bestellen/betalen');
			return;
		}

		if (options?.skipOnboardingCheck) return;

		if (!onboarding_completed) {
			if (!child_name) {
				yield call(historyReplace, '/naam-aanmaken');
				return;
			}
			if (!has_password) {
				yield call(historyReplace, '/wachtwoord-aanmaken');
				return;
			}
			if (!grade_id) {
				yield call(historyReplace, '/groep-kiezen');
				return;
			}
		}

		if (!child_name) {
			yield call(historyReplace, '/naam-toevoegen');
			return;
		}

		if (needs_to_fill_grade) {
			yield call(historyReplace, '/groep-aanpassen');
		}

		const { data: dailyReport } = yield call(processRequest, 'users/daily_report');
		yield put(dailyReportActions.getDailyReportSuccess(dailyReport));

		identifyHotjar(userData, dailyReport);

		yield put(learningPathActions.getLearningPathRequest());
	} catch (e) {
		yield put(sharedActions.getUserDataError());
		yield put(errorHandlerActions.handleError(e, 'page-blocking'));
	}
}

export function* handleUserLogout(action) {
	try {
		const { route } = action.payload;

		yield call(processRequest, 'auth/invalidate_token', 'POST');
		if (IS_MOBILE_APP && localStorage.deviceTokenStatus === 'registered') {
			yield put(
				pushNotificationActions.deregisterPushNotificationForDeviceRequest(
					localStorage.deviceToken,
					'shouldRestore',
				),
			);
		}
		yield call(removeAccessToken);

		yield put(sharedActions.clearUserData());
		yield put(packagesActions.cleanPackagesState());
		yield put(signUpActions.cleanSignUpState());
		yield put(learningPathActions.clearLearningPath());
		yield call(historyReplace, route || '/inloggen');
	} catch (e) {
		yield put(sharedActions.getUserDataError());
		yield put(errorHandlerActions.handleError(e, 'ignore'));
	}
}

export function* handleUpdateUserDataRequest({ payload }) {
	try {
		const userId = yield select(getUserId);
		const { data } = payload;

		yield call(processRequest, `users/${userId}`, 'PUT', {
			user: data,
		});

		yield put(sharedActions.getUserDataRequest());

		yield put(sharedActions.updateUserDataSuccess());
	} catch (e) {
		yield put(sharedActions.updateUserDataError());
		yield put(errorHandlerActions.handleError(e, 'notification'));
	}
}

export function* handleSendEventRequest({ payload }) {
	try {
		const { data: requestData } = payload;
		yield call(processRequest, `send_event`, 'POST', requestData);
	} catch (e) {
		yield put(errorHandlerActions.handleError(e, 'ignore'));
		yield put(sharedActions.sendEventError());
	}
}
