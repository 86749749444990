import i18n from 'i18next';
import { uniqueId, forEachRight } from 'lodash';
import { exerciseTypeCodesNext, PUNCTUATION_CHARACTERS } from '../../../services/Constants';
import { ACTIVE_CLASS, VISIBLE_CLASS } from '../Constants';

export const getMultipleAnswersCount = (currentQuestion) => {
	if (!currentQuestion) return 0;

	const { THE_BLANKS, OPEN_MULTIPLE } = exerciseTypeCodesNext;
	if ([THE_BLANKS, OPEN_MULTIPLE].includes(currentQuestion.type)) {
		return currentQuestion.question_display.filter((item) => typeof item === 'object').length;
	}

	return currentQuestion.answers.filter((item) => item.is_correct).length;
};

export const preparedData = (preparedMultipleAnswers) => {
	return Object.entries(preparedMultipleAnswers).reduce((acc, [k, v]) => {
		acc.push(v);
		return acc;
	}, []);
};

export const removeValue = (key, preparedMultipleAnswers) => {
	return Object.entries(preparedMultipleAnswers).reduce((acc, [k, v]) => {
		if (k !== String(key)) {
			acc[k] = v;
		}
		return acc;
	}, {});
};

export const setFocusOnFieldForMultipleTypes = (blankSectionRef) => {
	blankSectionRef &&
		blankSectionRef.current &&
		blankSectionRef.current.getElementsByTagName('input')[0] &&
		blankSectionRef.current.getElementsByTagName('input')[0].focus();
};

export const checkDoWeHaveIncorrectAnswer = (
	currentQuestion,
	preparedMultipleAnswers,
	isMCType,
) => {
	let hasAtLeastOneIncorrect = false;
	for (const [i, value] of Object.entries(preparedMultipleAnswers)) {
		const currentAnswer = currentQuestion.answers[i];
		const currentQuestionDisplay = currentQuestion.question_display[i];

		const answerItem = isMCType
			? currentAnswer?.is_correct && currentAnswer?.answer_display
			: currentQuestionDisplay[1];

		if (value.trim() !== (typeof answerItem !== 'string' ? answerItem : answerItem.trim()))
			hasAtLeastOneIncorrect = true;
	}
	return { isCorrect: !hasAtLeastOneIncorrect };
};

export const checkDoWeHaveAllCorrectAnswers = (currentQuestion, preparedMultipleAnswers) => {
	for (let i = 0; i < currentQuestion.answers.length; i++) {
		if (
			currentQuestion.answers[i].is_correct &&
			currentQuestion.answers[i].answer_display &&
			!preparedMultipleAnswers[i]
		)
			return { isCorrect: false };
	}
	return { isCorrect: true };
};

export const checkDoWeHaveIncorrectAnswers = (preparedBlanksMultipleAnswers) => {
	let hasAtLeastOneIncorrect = false;
	for (const [i, v] of Object.entries(preparedBlanksMultipleAnswers)) {
		if (!v.isCorrect) hasAtLeastOneIncorrect = true;
	}

	return { isCorrect: !hasAtLeastOneIncorrect };
};

export const isDictateAnswerCorrect = (currentQuestion, userAnswer) => {
	const answers = currentQuestion.answers.reduce((acc, item) => {
		item.answer_variations.forEach((el) => {
			acc.push(el.answer_text);
		});
		acc.push(item.answer_display);
		return acc;
	}, []);

	const contentObject = currentQuestion.question_display.find(
		(el, index) => typeof el === 'object',
	);

	const isCorrect = getIsCorrect(userAnswer, '', contentObject[2], answers);

	return { isCorrect };
};

const removePunctuation_ = (word) => {
	return word.filter((el) => PUNCTUATION_CHARACTERS.indexOf(el) === -1).join('');
};

const makeLowerCase_ = (word) => {
	return word.toLowerCase();
};

const removeSpaces_ = (word) => {
	return word.replace(/ /g, '');
};

export const getIsCorrect = (
	value,
	answerW,
	{ case_sensitive, punctuation_sensitive, space_sensitive },
	extraAnswerVariants,
) => {
	const splittedAnswers = extraAnswerVariants.map((item) => item.trim().replace('\u2019', "'"));
	const splittedProposedWord = value && value.split('');
	let finalAnswers = [...splittedAnswers];
	let proposedWord_ = value && value.trim().replace('\u2019', "'");

	if (!punctuation_sensitive && value) {
		proposedWord_ = removePunctuation_(splittedProposedWord);
		finalAnswers = finalAnswers.map((answer) => removePunctuation_(answer.split('')));
	}

	if (!case_sensitive && proposedWord_) {
		proposedWord_ = makeLowerCase_(proposedWord_);
		finalAnswers = finalAnswers.map((answer) => makeLowerCase_(answer));
	}

	if (!space_sensitive && proposedWord_) {
		proposedWord_ = removeSpaces_(proposedWord_);
		finalAnswers = finalAnswers.map((answer) => removeSpaces_(answer));
	}

	return finalAnswers.filter((item) => item.trim() === proposedWord_.trim()).length;
};

export const getExtraAnswersOptions = (answersArr, answerId) => {
	return answersArr.reduce((acc, answer) => {
		if (answer.id === answerId) {
			acc.push(answer.answer_display);
			if (answer.answer_variations && answer.answer_variations.length) {
				answer.answer_variations.forEach((item) => acc.push(item.answer_text));
			}
		}
		return acc;
	}, []);
};

export const getMaxItemLength = (items) => {
	return items.reduce((acc, item) => {
		if (acc < (item && item.length)) {
			acc = item.length;
		}
		return acc;
	}, 0);
};

export const getCorrectAnswer = (answers) =>
	answers.reduce((acc, item) => {
		if (item.is_correct) acc = item;
		return acc;
	}, {});

export const getCorrectAnswers = (answers) =>
	answers.reduce((acc, item) => {
		if (item.is_correct) acc.push(item.answer_display);
		return acc;
	}, []);

export const getScoreData = ({ questions, correct_answers_count, wrong_answers_count }) => {
	return {
		correct: correct_answers_count,
		incorrect: wrong_answers_count,
		questionsLeft: questions.slice(correct_answers_count + wrong_answers_count),
	};
};

export const getImageUrlsFromContent = (content) => {
	const srcRegex = /<img[^>]*src=['"]([^'"]*)['"]/g;

	const srcAttributes = [];
	let match;

	if (Array.isArray(content)) {
		content.forEach((item) => {
			if (typeof item == 'string') {
				while ((match = srcRegex.exec(content)) !== null) {
					srcAttributes.push(match[1]);
				}
			}
		});
	} else {
		while ((match = srcRegex.exec(content)) !== null) {
			srcAttributes.push(match[1]);
		}
	}

	return srcAttributes;
};

export const isCharactersLess = (content, checkLength) => {
	let string = '';

	if (Array.isArray(content)) {
		content.forEach((item) => {
			if (typeof item == 'string') {
				string += item;
			}
		});
	} else {
		string += content;
	}

	return string.length <= checkLength;
};

export const getInstructionOnboardingSteps = (instructionAudioOn) => {
	const onboardingInstructions = [
		{
			id: uniqueId(),
			message: i18n.t('exercise.instruction.onboarding.message_1'),
		},
		{
			id: uniqueId(),
			message: i18n.t('exercise.instruction.onboarding.message_2'),
		},
	];

	if (instructionAudioOn) {
		onboardingInstructions.push({
			id: uniqueId(),
			message: i18n.t('exercise.instruction.onboarding.message_3'),
		});
	}

	return [
		{
			isOnboarding: true,
			instructions: onboardingInstructions,
		},
		{
			isOnboardingCheck: true,
			instructionsAudioOn: [
				{
					id: uniqueId(),
					message: i18n.t('exercise.instruction.onboarding_check.audio_on_message_1'),
					audio_url:
						'https://storage.googleapis.com/np68-backend-staging/question_instructions/642184_340_da2b0ba7dd9dd9530ac0de8ef80a12d8.mp3',
				},
				{
					id: uniqueId(),
					message: i18n.t('exercise.instruction.onboarding_check.audio_on_message_2'),
					audio_url:
						'https://storage.googleapis.com/np68-backend-staging/question_instructions/642184_341_175c15308fdce855b994b6c8b760968e.mp3',
				},
			],
			instructionsAudioOff: [
				{
					id: uniqueId(),
					message: i18n.t('exercise.instruction.onboarding_check.audio_off_message_1'),
				},
				{
					id: uniqueId(),
					message: i18n.t('exercise.instruction.onboarding_check.audio_off_message_2'),
				},
			],
		},
	];
};

export const getInstructionSteps = (question, givenFeedback) => {
	const instructionSteps = question.question_instructions || [];

	if (
		instructionSteps.length &&
		!givenFeedback &&
		!instructionSteps[instructionSteps.length - 1].isFeedback
	) {
		instructionSteps.push({
			isFeedback: true,
			instructions: [{ id: uniqueId(), isFeedbackMessage: true }],
		});
	} else if (givenFeedback && instructionSteps[instructionSteps.length - 1].isFeedback) {
		instructionSteps.pop();
	}

	return instructionSteps;
};

export const getViewedInstructionData = (questionInstructions) => {
	let totalMessages = 0;
	let totalMessagesViewed = 0;

	const totalStepsViewed = questionInstructions?.reduce((stepsAcc, item) => {
		if (item.isFeedback || item.isOnboarding || item.isOnboardingCheck) return stepsAcc;

		const messagesViewedPerStep = item.instructions.reduce((messagesAcc, item) => {
			if (item.isViewed) messagesAcc++;
			return messagesAcc;
		}, 0);

		totalMessages += item.instructions.length;

		if (messagesViewedPerStep > 0) {
			totalMessagesViewed += messagesViewedPerStep;
			stepsAcc++;
		}

		return stepsAcc;
	}, 0);

	const totalSteps = questionInstructions?.filter(
		(item) => !item.isFeedback && !item.isOnboarding && !item.isOnboardingCheck,
	).length;

	const totalStepsViewedPercentage = Math.round((totalStepsViewed / totalSteps) * 100);

	return {
		totalMessages,
		totalSteps,
		totalMessagesViewed,
		totalStepsViewed,
		totalStepsViewedPercentage,
	};
};

export const calculateReadingProgress = (content) => {
	const contentLength = content.innerHTML.length;
	const activeContent = content.querySelectorAll(`.${ACTIVE_CLASS}, .${VISIBLE_CLASS}`);

	let activeContentLength = 0;
	for (let i = 0; i < activeContent.length; i++) {
		activeContentLength += activeContent[i].outerHTML.length;
	}

	return Math.round((activeContentLength / contentLength) * 100);
};

export const prepareTextForReading = (textRef) => {
	for (let i = 0; i < textRef.current.children.length; i++) {
		const element = textRef.current.children[i];
		if (['img', 'figure'].includes(element.tagName?.toLowerCase())) {
			textRef.current.removeChild(element);
			break;
		}
	}

	forEachRight(textRef.current.children, (element) => {
		if (element.tagName?.toLowerCase() === 'p' && element.textContent.trim().length === 0) {
			textRef.current.removeChild(element);
		}
	});
};

export const questionHasInstructionAudio = (question) => {
	return question.question_instructions?.some((item) =>
		item.instructions?.some((el) => el.audio_url),
	);
};
